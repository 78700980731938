.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 5;
}
.login_header {
  text-align: center;
}

.white_text {
  color: var(--color-fith);
  text-shadow: 3px 3px 6px black;
}
.first_row_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 30vh;
  justify-content: space-evenly;
  width: 100vw;
}
.div-Blur {
  backdrop-filter: blur(5px) brightness(0.75);
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 10px 10px 20px var(--color-fourth);
}
.div-Blur:hover {
  backdrop-filter: blur(7px) brightness(0.5);
  transition: all 0.5s ease-in-out;
  box-shadow: 15px 15px 25px var(--color-fourth);
}
.div-noBlur {
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -ms-filter: blur(0px);
  -o-filter: blur(0px);
  filter: blur(0px);
}
.text_container {
  text-align: center;
}
.text_container > label {
  text-align: center;
}
.absolute-container {
  position: absolute;
  /* left: 0; */
  /* top: 0; */
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.bottom_text {
  color: var(--color-fith);
  text-shadow: black 2px 2px 5px;
  width: calc(100vw - 2rem);
  position: relative;
  padding: 0.5rem;
  padding-bottom: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
}
.form_container {
  width: 100%;
  max-width: 100vw;
  height: calc(100vh - 9.5rem);
  display: flex;
  align-items: center;
  place-content: center;
  overflow: hidden;
}
.right_text_container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  max-width: 30rem;
}
.right_text_container > label {
  display: flex;
}
.form_paper {
  max-width: min(calc(var(--max-article-width) * 1 / 3), 80vw);
  min-width: 220px;
  width: 100%;
  display: flex;
  padding: 3rem;
  align-items: center;
  place-content: center;
  flex-direction: column;
  gap: 10px;
  z-index: 20;
}
@media (max-width: 960px) {
  .right_text_container {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .bottom_text {
    text-align: center;
  }
  .form_container {
    height: calc(100vh - 8rem);
  }
  .first_row_container {
    padding-top: 10vh;
    flex-direction: column-reverse !important;
  }
  .big_label {
    font-size: 5vh !important;
  }
  .form_paper {
    width: 85vw !important;
    max-width: none !important;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
.footer-container {
  display: flex;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 999;
  flex-direction: column; /* esto asegura que el label y el footer estén en una columna */
}
@media (max-height: 800px) {
  .big_label {
    font-size: 5vh !important;
  }
  .form_paper {
    height: 100vh !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    max-height: none !important;
  }
  .form_paper > label {
    font-size: 3vh;
  }

  .label {
    /* Aquí podrías añadir cualquier estilo adicional para tu label */
  }

  .footer {
    /* Aquí podrías añadir cualquier estilo adicional para tu footer */
  }
  .form_limits {
    height: calc(98vh - var(--header-height)) !important;
  }
  .bottom_text {
    opacity: 0 !important;
    display: none;
    height: 0;
  }
}
@media (max-height: 400px) {
  .form_limits {
    height: 100vh !important;
  }
  .footer-container {
    display: none;
  }
}

@media (max-height: 330px) {
  .right_text_container {
    display: none;
  }

  .first_row_container {
    padding-top: 4vh;
  }
}
@media (max-height: 1000px) and (max-width: 650px) {
  .login_header {
    display: none !important;
  }
}
