.key-value-table table{
    border-radius: 5rem;
    border-collapse: collapse !important;
    margin-top: 2rem; 
    padding:1rem;
    width:100%;
    
}
.key-value-table-row td{
    width:100%;
    padding: 1rem;;
    padding-left: 0.5rem;

    button{
        color:var(--color-fourth);
        // max-height: 8px!important;
    }
   
}

.key-value-table-row:hover td{
    button{
        color:var(--color-fourth);
    }
    
    background: var(--color-fourth)!important ;
    // background-image: linear-gradient(to bottom,var(--color-fourth),var(--color-eighteenth))!important;
    color:var(--color-fith);
    box-shadow: 0px 20px 16px #00000029!important;
}

.key-value-table-row:hover td:first-child{
    border-top-left-radius: 15px!important;
    border-bottom-left-radius: 15px!important;
}

.key-value-table-row:hover td:last-child{
    border-top-right-radius: 15px!important;
    border-bottom-right-radius: 15px!important;
}
.key-value-table .ant-table-thead > tr > th {
    text-align: left;
    width:100%;
}
  
.key-value-table .ant-table-tbody > tr > td {
    text-align: left;
}