.header {
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
  position: relative;
  border-bottom: 5px solid rgb(var(--color-secondary));
  height: var(--header-height);
}
/* .header::before{
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	background-size: cover;
	height:var(--header-height);
	/* background-image: url("/public/images/hermoso-paisaje-montana-2.jpg"); */
/* background-image: url("/public/images/header_background.png");
	filter: brightness(40%);
} */
.header_logo {
  justify-self: flex-end;
  height: calc(var(--header-height) * 2 / 3);
  margin-top: calc(var(--header-height) * 1 / 6);
  cursor: pointer;
}
.header_logo_v2 {
  justify-self: flex-start;
  height: calc(var(--header-height) * 2 / 3);
  margin-top: calc(var(--header-height) * 1 / 6);
  cursor: pointer;
}
.header_logo_img {
  height: 100%;
}
.header_title {
  color: var(--color-fith);
  font-size: 3rem;
  display: flex;
  align-items: center;
  height: var(--header-height);
  justify-content: flex-start;
  text-align: left;
}
@media only screen and (max-width: 960px) {
  .header_title {
    font-size: 3vh !important;
  }
}

.logout {
  z-index: 999;
  padding: 0rem;
  /*color: #FF94A5; */
  border-radius: 2px;
}
.right-box {
  position: absolute !important;
  right: 2rem;
}
.logout-color {
  color: rgb(var(--color-secondary));
}
.button-box {
  width: 80%;
  max-width: min(calc(100vw - 14rem), 40vw);
  padding-left: 4rem;
  display: flex;
  justify-content: space-evenly;
}
.button-oval {
  border-radius: 1rem; /* Ajusta este valor para controlar la ovalación del botón */
  padding: 8px 24px;
}
.button-effect {
  background-size: 200% auto;
  color: var(--color-fith);
  background-color: var(--color-fourth);
  border-radius: 0.8rem;
  border: 1px solid var(--color-six) !important;
  filter: opacity(0.8);
  cursor: pointer;
  border-color: rgba(--color-secondary);
  transition: filter 1s, background-position 1s, background-image 1s;
}
.button-effect:hover {
  filter: brightness(1.4);
  background-position: right center;
  /* background-color: rgb(var(--color-secondary)) */
  background-image: linear-gradient(
    150deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgb(var(--color-secondary)) 13%,
    rgb(var(--color-secondary)) 13%,
    rgba(0, 0, 0, 0.5) 51%,
    rgb(var(--color-secondary)) 100%
  );
}
/* Función personalizada para convertir hexadecimal a RGB */
@function hexToRgb($hex) {
  @return red($hex), green($hex), blue($hex);
}

.button-selected {
  filter: opacity(1) !important;
  background-color: rgb(var(--color-secondary));
  /* background-image: linear-gradient(120deg, rgba(0, 0, 0, 0.5) 0%, rgb(var(--color-secondary)) 23%, rgb(var(--color-secondary)) 28%, rgba(0, 0, 0, 0.5) 51%, rgb(var(--color-secondary)) 100%); */
}
.language-selector {
}
