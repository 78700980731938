article {
  width: 100%;
}

.main_general_description {
  max-width: var(--max-article-width);
  color: var(--color-fourth);
  width: 100%;
  text-align: center;
  text-justify: inter-character;
  text-wrap: balance;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_general_description_title {
  max-width: var(--max-article-width);
  color: var(--color-fourth);
  width: 100%;
  font-size: 1.5rem;
  text-decoration: solid;
  padding: 1rem 0%;
  /*text-align: justify;*/
  text-justify: inter-character;
  text-wrap: balance;
  display: flex;
  align-items: center;
  /*justify-content: center;*/
}
.main_general_description_box {
  width: 95%;
  padding: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
}
@media screen and (max-width: 960px) {
  .main_general_description_box {
    width: 95% !important;
  }
}
