/* Agrega este CSS a tu archivo de estilos */
.grid-container {
    display: grid;
    width: 100%;
    
    
    
    padding-top: 1rem;
    gap:0px;
    box-sizing: border-box; /* Asegura que el padding se incluye en el ancho total */

  }
  
  /* Puntos de ruptura para adaptar el número de columnas */
  @container (min-width: 2200px) {
    .grid-container {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }
  
  @container (min-width: 1400px) and (max-width: 2200px) {
    .grid-container {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  
  @container (max-width: 1400px) {
    .grid-container {
      grid-template-columns: 1fr;
    }
  }
  
  .grid-item {
    width: 100%;
    height: 100%;
    min-height: 300px;
    box-sizing: border-box; /* Asegura que el padding se incluye en el ancho total */
  }
  