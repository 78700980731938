
/* Input style */
.input-container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    max-height: 55px;
    max-width: 99%;
  }
  
  .input-container:focus-within label {
    transform: translate(0, 12px) scale(0.8);
    color: #0a53e4;
  }
  
  .input-container .filled {
    transform: translate(0, 12px) scale(0.8);
  }

  
  .input-container label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 23px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #6f81a5;
    font-size: 16px;
    line-height: 1;
    left: 16px;
  }
  
  .input-container input {
    height: 64px;
    border-radius: 4px;
    border: none;
    padding: 24px 16px 4px 16px;
    font-size: 16px;
    line-height: 1;
    outline: none;
    /*box-shadow: none;*/
    box-shadow: 0 0 0 1px #79b1ff;

    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
  
  .input-container input:focus {
    box-shadow: 0 0 0 2px #79b1ff;
  }
  .input-container a {
    height: 64px;
    border-radius: 4px;
    border: none;
    padding: 24px 16px 4px 16px;
    font-size: 16px;
    line-height: 1;
    outline: none;
    /*box-shadow: none;*/
    box-shadow: 0 0 0 1px #79b1ff;

    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
  
  .input-container a:focus {
    box-shadow: 0 0 0 2px #79b1ff;
  }